<template>
  <div class="homeBG" id="homeBG">   
    <canvas id='modelBox' class="modelBox"></canvas>
    <div class="home-bg1"></div>
  </div>
</template>
<script>
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import * as THREE from 'three'
export default {
  data () {
    return {
      yy: null,
      boxId: 'homeBG',
      width: 0,
      height: 0,
      scene: null,
      camera: null,
      renderer: null,
      container: null,
      publicPath: process.env.BASE_URL,
      group: null,
      maxParticleCount: 1000,
      particleCount: 500,
      r: 800,
      rHalf: 400,
      particlesData: [],
      effectController: {
        showDots: true,
        showLines: true,
        minDistance: 78,
        limitConnections: false,
        maxConnections: 2,
        particleCount: 500
      }
    }
  },
  mounted () {
    if (this.pageWidth > 900) {
      this.init()
    }    
  },
  methods: {
    init () {
      this.width = document.getElementById(this.boxId).clientWidth
      this.height = document.getElementById(this.boxId).clientHeight
      this.scene = new THREE.Scene() 
      this.camera = new THREE.PerspectiveCamera(45, this.width/this.height, 1, 4000)
      this.initRenderer()
      this.initLight()
      this.initEvent()
      this.initModel()
      this.move()
      this.render() 
    },
    initRenderer () {    
      var canvas = document.getElementById('modelBox')
      canvas.width = this.width
      canvas.height = this.height
      var context = canvas.getContext( 'webgl2', { alpha: true, antialias: true } );
      this.renderer = new THREE.WebGLRenderer( { canvas: canvas, context: context } );
      this.container = canvas
      this.renderer.setSize(this.width, this.height)
      this.renderer.setPixelRatio( window.devicePixelRatio );
      this.renderer.shadowMap.enabled = true;
      
      this.camera.position.set(500,500,200)

      this.controls = new OrbitControls(this.camera, this.container);
      this.controls.enabled = false 
    },
    initLight () { // 场景光    
      let light1 = new THREE.AmbientLight(0xffffff, 1)
      this.scene.add(light1)  
    },
    initEvent () {
      window.addEventListener('resize', this.onWindowResize)
    },
    onWindowResize () {
      this.width = document.getElementById(this.boxId).clientWidth
      this.height = document.getElementById(this.boxId).clientHeight
      this.renderer.setSize(this.width, this.height)
      this.camera.aspect = this.width / this.height
      this.camera.updateProjectionMatrix()
    },
    destroyWindowResize () {
      this.scene.clear()
      this.renderer.dispose()
      this.renderer.forceContextLoss()
      this.renderer.content = null
      cancelAnimationFrame(this.animationID)
      let gl = this.renderer.domElement.getContext('webgl')
      gl && gl.getExtension('WEBGL_lose_context').loseContext()
      window.removeEventListener('resize', this.onWindowResize)
    },
    render () { // 渲染方法
      this.move()
      this.renderUpdate()
      this.renderer.render(this.scene, this.camera)
      this.animationID = requestAnimationFrame(() => {this.render()})
    },
    renderUpdate () {
      if (this.group) {
        let time = Date.now() * 0.001
        this.group.rotation.y = time * 0.1
      }
    },
    initModel () {
      this.group = new THREE.Group()
      this.group.name = 'dotList'
      this.scene.add(this.group)

      const segments = this.maxParticleCount * this.maxParticleCount;

      this.positions = new Float32Array( segments * 3 );
      this.colors = new Float32Array( segments * 3 );

      const pMaterial = new THREE.PointsMaterial( {
        color: 0x888888,
        size: 5,
        blending: THREE.AdditiveBlending,
        transparent: true,
        sizeAttenuation: false,
        opacity: 0.4,
        map: new THREE.TextureLoader().load(`${this.publicPath}image/dot.png`)
      } );

      this.particles = new THREE.BufferGeometry();
      this.particlePositions = new Float32Array( this.maxParticleCount * 3 );

      for ( let i = 0; i < this.maxParticleCount; i ++ ) {

        const x = Math.random() * this.r - this.r / 2;
        const y = Math.random() * this.r - this.r / 2;
        const z = Math.random() * this.r - this.r / 2;

        this.particlePositions[ i * 3 ] = x;
        this.particlePositions[ i * 3 + 1 ] = y;
        this.particlePositions[ i * 3 + 2 ] = z;

        this.particlesData.push( {
          velocity: new THREE.Vector3( - 1 + Math.random() * 2, - 1 + Math.random() * 2, - 1 + Math.random() * 2 ),
          numConnections: 0
        } );

      }

      this.particles.setDrawRange( 0, this.particleCount );
      this.particles.setAttribute( 'position', new THREE.BufferAttribute( this.particlePositions, 3 ).setUsage( THREE.DynamicDrawUsage ) );

      this.pointCloud = new THREE.Points( this.particles, pMaterial );
      this.group.add( this.pointCloud );

      const geometry = new THREE.BufferGeometry();

      geometry.setAttribute( 'position', new THREE.BufferAttribute( this.positions, 3 ).setUsage( THREE.DynamicDrawUsage ) );
      geometry.setAttribute( 'color', new THREE.BufferAttribute( this.colors, 3 ).setUsage( THREE.DynamicDrawUsage ) );

      geometry.computeBoundingSphere();

      geometry.setDrawRange( 0, 0 );

      const material = new THREE.LineBasicMaterial( {
        vertexColors: false,
        blending: THREE.AdditiveBlending,
        transparent: true,
        color: 0xEFEFEF
      } );

      this.linesMesh = new THREE.LineSegments( geometry, material );
      this.group.add( this.linesMesh );
    },
    move () {
      let vertexpos = 0;
      let colorpos = 0;
      let numConnected = 0;

      for ( let i = 0; i < this.particleCount; i ++ )
      this.particlesData[ i ].numConnections = 0;

      for ( let i = 0; i < this.particleCount; i ++ ) {

        const particleData = this.particlesData[ i ];

        this.particlePositions[ i * 3 ] += particleData.velocity.x;
        this.particlePositions[ i * 3 + 1 ] += particleData.velocity.y;
        this.particlePositions[ i * 3 + 2 ] += particleData.velocity.z;

        if ( this.particlePositions[ i * 3 + 1 ] < - this.rHalf || this.particlePositions[ i * 3 + 1 ] > this.rHalf )
          particleData.velocity.y = - particleData.velocity.y;

        if ( this.particlePositions[ i * 3 ] < - this.rHalf || this.particlePositions[ i * 3 ] > this.rHalf )
          particleData.velocity.x = - particleData.velocity.x;

        if ( this.particlePositions[ i * 3 + 2 ] < - this.rHalf || this.particlePositions[ i * 3 + 2 ] > this.rHalf )
          particleData.velocity.z = - particleData.velocity.z;

        if ( this.effectController.limitConnections && particleData.numConnections >= this.effectController.maxConnections )
          continue;

        for ( let j = i + 1; j < this.particleCount; j ++ ) {

          const particleDataB = this.particlesData[ j ];
          if ( this.effectController.limitConnections && particleDataB.numConnections >= this.effectController.maxConnections )
            continue;

          const dx = this.particlePositions[ i * 3 ] - this.particlePositions[ j * 3 ];
          const dy = this.particlePositions[ i * 3 + 1 ] - this.particlePositions[ j * 3 + 1 ];
          const dz = this.particlePositions[ i * 3 + 2 ] - this.particlePositions[ j * 3 + 2 ];
          const dist = Math.sqrt( dx * dx + dy * dy + dz * dz );

          if ( dist < this.effectController.minDistance ) {

            particleData.numConnections ++;
            particleDataB.numConnections ++;

            const alpha = 1.0 - dist / this.effectController.minDistance;

            this.positions[ vertexpos ++ ] = this.particlePositions[ i * 3 ];
            this.positions[ vertexpos ++ ] = this.particlePositions[ i * 3 + 1 ];
            this.positions[ vertexpos ++ ] = this.particlePositions[ i * 3 + 2 ];

            this.positions[ vertexpos ++ ] = this.particlePositions[ j * 3 ];
            this.positions[ vertexpos ++ ] = this.particlePositions[ j * 3 + 1 ];
            this.positions[ vertexpos ++ ] = this.particlePositions[ j * 3 + 2 ];

            this.colors[ colorpos ++ ] = alpha;
            this.colors[ colorpos ++ ] = alpha;
            this.colors[ colorpos ++ ] = alpha;

            this.colors[ colorpos ++ ] = alpha;
            this.colors[ colorpos ++ ] = alpha;
            this.colors[ colorpos ++ ] = alpha;

            numConnected ++;
          }
        }
      }

      this.linesMesh.geometry.setDrawRange( 0, numConnected * 2 );
      this.linesMesh.geometry.attributes.position.needsUpdate = true;
      this.linesMesh.geometry.attributes.color.needsUpdate = true;

      this.pointCloud.geometry.attributes.position.needsUpdate = true;
    },
  },
  beforeDestroy () {
    if (this.pageWidth > 900 && this.scene) {
      this.destroyWindowResize()
    }
  },
}
</script>
<style scoped lang="stylus"> 
.homeBG
  width 95%
  height 100vh
  position absolute
  top 0
  left 50%
  overflow hidden
  z-index 0
  transform translate3d(-50%, 0, 0)
  .modelBox, .home-bg1
    position absolute
    width 100%
    height 100%
    top 0
    left 0
  .home-bg1
    z-index 1
    background radial-gradient( rgba(255,255,255,0) 0%, rgba(255,255,255,0) 10%, #FFFFFF 90%, #FFFFFF 100%);
</style>